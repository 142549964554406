import { useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';

const PageSizePagination = ({ meta, params, setParams }) => {
	const [open, setOpen] = useState(false);
	const pageSize = meta?.pageSize;
	const options = [10, 20, 50, 100, 250];

	// Methods
	const onToggle = () => setOpen(!open);

	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop id='page_size_drop' open={open} onClose={onClose}>
			<DropMenu open={open} placement='top'>
				<DropList>
					{options.map((option, i) => (
						<DropItem key={i}>
							<DropBtn
								nextIcon={option === pageSize ? 'check' : ''}
								onClick={() => setParams({ ...params, pageSize: option })}>
								{option} Results
							</DropBtn>
						</DropItem>
					))}
				</DropList>
			</DropMenu>
			<DropToggle variant='border' active={open} onClick={onToggle}>
				{pageSize} Results
			</DropToggle>
		</Drop>
	);
};

export { PageSizePagination };
